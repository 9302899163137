$datepicker__background-color: #F8FBFF !default;
$datepicker__border-color: #A1B3C9 !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #0D3058 !default;
$datepicker__selected-color: #A1B3C9 !default;
$datepicker__text-color: #0D3058 !default;
$datepicker__header-color: #0D3058 !default;
// $datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%)
//   !default;
$datepicker__navigation-disabled-color: red;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: 'Open Sans', 'Helvetica Neue', sans-serif,
  sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

$background-time-color: #F8FBFF;