@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Rubik&family=Russo+One&display=swap");

.main-container {
  padding-top: 0 !important;
  margin-left: 240px;
  transition: margin-left 0.3s ease;
}

.sidenav-toggled .main-container {
  margin-left: 80px;
}

@media screen and (max-width: 640px) {
  .sidenav-toggled .main-container {
    margin-left: 0px;
  }
}

html,
body,
#root {
  height: 100%;
  position: relative;
}

.padding-top-25 {
  padding-top: 25px;
  padding-left: 38px !important;
}

.page-container {
  margin-left: 30px;
  margin-top: 30px;
}

.container {
  &.custom-layout {
    padding-left: 20px;
    padding-right: 20px;
  }

  .subheader-layout {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
  }

  .card {
    .card-header {
      box-shadow: none;
      border-bottom: 1px solid #dbdbdb;
      display: block;
      &.header-spacer {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 1.25rem;
      }
    }

    .card-body {
      &.body-spacer {
        flex: 1 1 auto;
        padding: 1.25rem;
      }
    }
  }

  .tabs ul,
  .tabs a {
    border-bottom-color: transparent;
  }

  .tabs a:hover {
    border-bottom-color: #363636;
  }

  .tabs.is-bigger {
    font-size: 0.95rem;
  }
}

.the-hand {
  cursor: pointer;
}

.no-border-section {
  border: none !important;
}

.form-section {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 20px;
  padding-top: 20px;
  h2 {
    padding-bottom: 20px;
    color: #2f9bff;
    font-weight: 500;
  }
}

.loading-icon {
  display: flex;
  flex: 1;
  position: fixed;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.95);
  height: 100%;
  width: 100%;
}

.image-loading-icon {
  svg {
    width: 300px;
  }
}

.label {
  color: #999595;
  display: block;
  font-size: 1rem;
  font-weight: 400;
}

.pagination {
  padding-top: 30px;
  border-top: 1px dashed #ccc;
  margin-top: 25px;

  .pagination-previous,
  .pagination-next {
    color: #0162e8;
  }

  .pagination-list {
    .pagination-link {
      &.is-current {
        a {
          color: white !important;
        }
      }
    }
  }

  .pagination-link a {
    width: 100%;
  }
}

.modal-content {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fbfaff;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  overflow: scroll;

  &.no-scroll {
    overflow: hidden;
  }
}

.columns {
  &.detail-columns {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .detail-header {
    font-size: 16px;
    font-weight: 500;
    color: #2f9bff;
  }
  .detail-label {
    font-size: 14px;
    font-weight: 500;
    color: #242f48;
    padding-bottom: 5px;
  }
  .detail-value {
    color: #7987a1;
  }
}

.dashed-border {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 20px;
}

.detail-hand-cursor,
.generic-hand-cursor {
  cursor: pointer;
}

.navigator-hand-cursor {
  cursor: pointer;

  &:hover {
    color: #363636;
  }
}

.search-bar {
  padding: 10px 0;
  max-width: 300px;
}

.logo-container {
  text-align: center;
  margin-top: 14px;
}
.logo-title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 40px;
  color: #7a7a7a;
}

.job-listing-title {
  font-size: 20px;
  font-weight: 700;
  color: #7a7a7a;
}

.modal-content-wrapper {
  padding: 20px;
  text-align: center;
  font-size: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.message-content {
  padding: 20px;
}

.expand-column-styled {
  background: #ccc6;
  padding: 10px 0px;

  .detail-value {
    max-height: 160px;
    overflow-y: scroll;
  }
}

.application-list {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #ccc;
}

.page-sub-heading {
  font-size: 17px;
  font-weight: 700;
  color: #7a7a7abd;
}

.bottom-top-spacing {
  padding-bottom: 10px;
  padding-top: 10px;
}

.checkbox-separator {
  margin-bottom: 10px;
  padding-bottom: 20px;
  max-width: 400px;

  .checkbox {
    width: 100%;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }

  .checkbox-subsection {
    padding-top: 10px;
  }

  .checkbox-message-section {
    color: #fff;
    max-width: 400px;
    background-color: #a1b3c9;
    border-radius: 5px;
    padding: 10px;
  }
}

.cursor-default {
  cursor: default;
}

.button-custom-small {
  width: 55px;
}

.is-saved {
  background-color: darksalmon;
  color: white;

  &:hover {
    background-color: darksalmon;
    color: white;
  }
}

.error-message {
  height: 21px;

  span {
    font-size: 13px;
    color: red;
  }
}

.contact-form {
  .modal-content-border {
    position: relative;

    .modal-close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.control {
  text-align: left;
}
