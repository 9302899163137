.background-countainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-outer-container {
  width: 390px;
  height: 580px;
  background-color: #e5e5e5;
  padding: 2px;
  border-radius: 24px;
  -webkit-box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.28);
}

.login-inner-container {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: -220px;
}

.login-title {
  font-family: "Russo One", sans-serif;
  font-size: 32px;
  color: white;
}

.login-sub-title {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  color: white;
}

.login-logo {
  width: 100px;
  padding-bottom: 20px;
}

.header-title-container {
  text-align: center;
  padding-top: 30px;
}

.login-form {
  text-align: center;
  margin-top: 60px;
}

.login-form .form-label {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  padding: 10px;
  color: #929292;
}

.student-login,
.provider-login,
.admin-login {
  padding: 10px;
}

.student-login {
  .button {
    background-color: #2572b9;
    color: white;
    border-radius: 10px;

    &:hover {
      background-color: #3d90dd;
    }
  }
}

.provider-login {
  .button {
    color: #2572b9;
    border-color: #2572b9;
    border-radius: 10px;
    &:hover {
      border-color: #69b1f3;
      color: #69b1f3;
    }
  }
}

.admin-login {
  .button {
    &:hover {
      text-decoration: none;
    }
  }
}

.is-fullwidth-80 {
  width: 90%;
}

.is-no-border {
  border-width: 0px;
  text-decoration: underline;
}
