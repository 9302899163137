.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: #14112d;
  width: 240px;
  max-height: 100%;
  z-index: 1024;
  background: #fff;
  -webkit-box-shadow: 0px 8px 14.72px 1.28px rgb(229 228 230 / 50%);
  box-shadow: 0px 8px 14.72px 1.28px rgb(229 228 230 / 50%);
  border-right: 1px solid #e3e3e3;
  transition: left 0.3s ease, width 0.3s ease;
}

.profile-image-outer {
  overflow: hidden;
  width: 72px;
  background: #fff;
  margin: auto;

  img {
    height: auto;
    max-width: 100%;
    border: 2px solid #c9d2e8;
    background: #fff;
    padding: 3px;
    border-radius: 50%;
  }
}

.app-sidebar-header {
  height: 64px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-name {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #14112d;
  font-weight: 500;
  text-align: center;
}

.profile-position {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-align: center;
  color: #8991a5;
}

.profile-details {
  margin-top: 10px;
}

.jobseeker-logo {
  display: inline-block;
  width: 186px;
  height: 46px;
  line-height: 98px;
}

.jobseeker-logo-notext {
  display: none;
}

.submmenu-options {
  padding-top: 15px;
}

.sidenav-toggled {
  .jobseeker-logo {
    display: none;
  }

  .jobseeker-logo-notext {
    display: inline-block;
  }

  .profile-image-outer {
    width: 55px;
  }

  .profile-details {
    display: none;
  }

  .mainmenu-heading {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    span {
      display: none;
    }
  }

  .submmenu-options {
    display: none;
  }
}

.app-sidebar-menu {
  margin-top: 20px;
  li {
    padding: 10px 0;
    border-bottom: 1px dashed #f1f1f1;
  }
}

.mainmenu-heading {
  line-height: 20px;
  display: flex;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  color: #5b6e88;

  &.active {
    border-left: 3px solid #0162e8;
    color: #0162e8;
  }

  svg.menu-icon {
    font-size: 20px;
  }

  span {
    padding-left: 10px;
  }
}

a.mainmenu-option {
  display: flex;
  font-size: 13px;
  color: #5b6e88;
  margin-bottom: 5px;
  margin-left: 30px;

  &.active {
    color: #0162e8;
  }

  .submenu-icon {
    margin-top: 6px;
    font-size: 8px;
  }

  span {
    padding-left: 13px;
  }
}
