.content-container {
  &.table {
    padding: 0px;
  }
}

.custom-active-modal {
  display: flex !important;
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  z-index: 1050;

  .custom-modal-background {
    background-color: rgba(255, 255, 255, 0.86);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .modal-close {
    background: #5b6e88;
  }
}
