.breadcrumb {
  .maincrumb {
    font-size: 20px;
    font-weight: bold;
    color: #7a7a7a;
    span {
      padding-right: 5px;
    }
  }

  .subcrumb {
    line-height: 30px;
    font-size: 15px;
    color: #b5b5b5;
    span {
      padding-left: 5px;
    }
  }
}
